.footer {
  background-color: #fe3030;
  padding: 80px;
}

@media only screen and (max-width: 720px) {
  .footer {
    padding: 40px;
  }
  .footerImage {
    width: 100%;
  }
}
