.app {
  background-color: #fedd30;
  text-align: center;
}

.twoColContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 40px 0px 40px;
  max-width: 1853px;
  margin: 0 auto;
}

.colOne {
  text-align: right;
  width: 45%;
  padding-right: 5%;
  max-width: 500px;
  font-weight: 300;
  font-size: 17px;
  line-height: 25.52px;
  color: #fff;
}

.colTwo {
  text-align: left;
  width: 50%;
  max-width: 500px;
  color: #fff;
  font-weight: 700;
  font-size: 70px;
  line-height: 71.82px;
  display: inline-block;
  letter-spacing: -5px;
}

.deezGuysImage {
  width: 100%;
  background-image: url("../src/images/deezguys.png");
  background-size: contain;
  background-position: bottom;
  height: 50vh;
  background-repeat: no-repeat;
}

.roadmap {
  text-align: center;
  max-width: 975px;
  margin: 0 auto;
  font-size: 17px;
  line-height: 22px;
  font-weight: normal;
  color: #000;
  padding: 60px 60px 100px 60px;
}

.roadmap p {
  padding-bottom: 15px;
}

.deezGuysImageLong {
  width: 100%;
  background-image: url("../src/images/deezguys-long.png");
  background-size: auto;
  background-position: center;
  height: 314px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 730px) {
  .twoColContainer {
    flex-direction: column-reverse;
  }
  .colOne {
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }
  .colTwo {
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 1622px) {
  .deezGuysImage {
    background-size: contain;
    background-position: bottom;
    height: 30vh;
    background-repeat: no-repeat;
  }
  .deezGuysImageLong {
    background-size: cover;
    background-position: center;
    height: 20vh;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 850px) {
  .deezGuysImage {
    background-size: cover;
    background-position: bottom;
    height: 30vh;
    background-repeat: no-repeat;
  }
  .deezGuysImageLong {
    background-size: cover;
    background-position: center;
    height: 20vh;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 509px) {
  .roadmap h1 {
    font-size: 42px;
    line-height: 45px;
  }
  .roadmap p {
    font-size: 16px;
    line-height: 21px;
  }
}
