.appHeader {
  max-width: 1853px;
  margin: 0 auto;
  padding: 0 40px;
}

.logo {
  margin: 20px auto 0 auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: -140px;
}

.introImage {
  width: 100%;
  max-width: 720px;
  height: auto;
  display: block;
  align-self: flex-end;
}

h1 {
  font-weight: 700;
  font-size: 70px;
  line-height: 71.82px;
  display: inline-block;
  letter-spacing: -5px;
}

.intro {
  width: 40%;
  margin-bottom: 40px;
}

.buttonContainer {
  display: flex;
  margin-top: 60px;
}

a {
  border: 0;
  background-color: #000;
  width: 238px;
  height: 48px;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  margin-right: 28px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Space Grotesk";
  font-size: 14px;
  line-height: 48px;
  display: block;
  letter-spacing: 0.75px;
  text-decoration: none;
  text-align: center;
  align-items: center;
}

a:hover {
  opacity: 0.7;
}

.button {
  display: inline-block;
  position: relative;
  border: 0;
  overflow: hidden;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  color: #fff;
  background-color: #000;
  font-family: "Space Grotesk";
  font-weight: 700;
  letter-spacing: 0.75px;
}

.button span {
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.button:before,
.button:after {
  content: "";
  position: absolute;
  top: 1.1em;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.4s, opacity 0.6s;
  -moz-transition: 0.4s, opacity 0.6s;
  -o-transition: 0.4s, opacity 0.6s;
  transition: 0.4s, opacity 0.6s;
}

/* :before */

.button:before {
  content: attr(data-hover);
  -webkit-transform: translate(-150%, 0);
  -moz-transform: translate(-150%, 0);
  -ms-transform: translate(-150%, 0);
  -o-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
}

/* :after */

.button:after {
  content: attr(data-active);
  -webkit-transform: translate(150%, 0);
  -moz-transform: translate(150%, 0);
  -ms-transform: translate(150%, 0);
  -o-transform: translate(150%, 0);
  transform: translate(150%, 0);
}

/* Span on :hover and :active */

.button:hover span,
.button:active span {
  opacity: 0;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -o-transform: scale(0.3);
  transform: scale(0.3);
}

/*  
    We show :before pseudo-element on :hover 
    and :after pseudo-element on :active 
*/

.button:hover:before,
.button:active:after {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

/* 
  We hide :before pseudo-element on :active
*/

.button:active:before {
  -webkit-transform: translate(-150%, 0);
  -moz-transform: translate(-150%, 0);
  -ms-transform: translate(-150%, 0);
  -o-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

@media only screen and (max-width: 959px) {
  .logo {
    width: 300px;
    height: auto;
  }
  h1 {
    font-size: 50px;
    line-height: 52px;
  }
  .container {
    margin-top: -80px;
  }
}

@media only screen and (max-width: 829px) {
  .container {
    flex-direction: column;
  }
  .container {
    margin-top: 0px;
  }
  .appHeader {
    padding: 0;
  }
  .intro {
    width: 60%;
  }
  /* .introImage {
    width: 90%;
    max-width: 520px;
    height: auto;
    display: block;
    margin: 0 auto;
  } */
  .introImage {
    display: none;
  }
  .button {
    display: none;
  }
  a {
    width: 100%;
  }
}

@media only screen and (max-width: 543px) {
  .buttonContainer {
    display: block;
  }
  .buttonContainer a {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .intro {
    width: 80%;
  }
}
