button {
  border: 0;
  background-color: #000;
  width: 238px;
  height: 48px;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  margin-right: 28px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Space Grotesk";
  font-size: 14px;
  line-height: 16px;
  display: block;
  letter-spacing: 0.75px;
}

button:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 829px) {
  button {
    margin-right: 5px;
  }
}
